<!-- @format -->

<template>
  <div class="flex flex-col gap-8">
    <form
      ref="form"
      class="flex flex-col gap-3 p-6 bg-white border border-textGray-600 rounded-lg xl:relative shadow-lg"
    >
      <h3 class="font-medium text-xl mb-8">
        <span v-if="props.mode != 'view'">Create</span> Action Plan
      </h3>
      <label
        class="block text-gray-700 font-semibold text-xs"
        for="grid-first-name"
      >
        <span v-if="props.mode != 'view'">Select</span> Practice<span
          class="text-red"
          >*</span
        >
      </label>
      <v-select
        :multiple="false"
        :closeOnSelect="true"
        class="w-full text-gray-700"
        :options="practices"
        label="practice_name"
        placeholder="Select Practice"
        @option:selected="setPractice"
        v-model="selectedPractice"
        :disabled="viewMode || disablePracticeDropdown"
      >
      </v-select>
      <div v-if="qofTrue === 'true'" class="flex justify-between">
        <div class="flex flex-col w-1/2 pr-3 gap-3">
          <label
            class="block text-gray-700 font-semibold text-xs"
            for="grid-first-name"
          >
            <span v-if="props.mode != 'view'">Add</span> Indicator<span
              class="text-red"
              >*</span
            >
          </label>
          <input
            class="block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            type="text"
            placeholder="Indicator Name"
            :maxlength="50"
            v-model="actionPlanForm.indicator"
            :disabled="!selected || viewMode"
            :class="
              v$.actionPlanForm.indicator.$error === true
                ? 'border border-red'
                : 'border border-gray-300'
            "
          />
          <p
            class="text-red"
            v-for="error in v$.actionPlanForm.indicator.$errors"
            :key="error.$uid"
          >
            {{ error.$message }}
          </p>
        </div>
        <div class="flex flex-col gap-3 w-1/2 pl-3">
          <label
            class="block text-gray-700 font-semibold text-xs"
            for="grid-first-name"
          >
            <span v-if="props.mode != 'view'">Add</span> Sub Indicator<span
              class="text-red"
              >*</span
            >
          </label>
          <input
            class="block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            type="text"
            placeholder="Sub Indicator Name"
            :maxlength="50"
            v-model="actionPlanForm.sub_indicator"
            :disabled="!selected || viewMode"
            :class="
              v$.actionPlanForm.sub_indicator.$error === true
                ? 'border border-red'
                : 'border border-gray-300'
            "
          />
          <p
            class="text-red"
            v-for="error in v$.actionPlanForm.sub_indicator.$errors"
            :key="error.$uid"
          >
            {{ error.$message }}
          </p>
        </div>
      </div>
      <label
        v-if="qofTrue !== 'true'"
        class="block text-gray-700 font-semibold text-xs"
        for="grid-first-name"
      >
        <span v-if="props.mode != 'view'">Select</span> Survey Question<span
          class="text-red"
          >*</span
        >
      </label>
      <v-select
        v-if="qofTrue !== 'true'"
        :multiple="false"
        :closeOnSelect="true"
        class="w-full text-gray-700"
        :options="questions"
        label="question"
        placeholder="Select Question"
        @option:selected="setQuestion"
        v-model="selectedQuestion"
        :disabled="viewMode || disableQuestionDropdown"
      >
      </v-select>
      <label
        class="block text-gray-700 font-semibold text-xs"
        for="grid-first-name"
      >
        Agenda<span class="text-red">*</span>
      </label>
      <input
        class="block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
        id="grid-first-name"
        type="text"
        placeholder="Agenda here"
        :maxlength="255"
        v-model="actionPlanForm.agenda"
        :disabled="!selected || viewMode"
        :class="
          v$.actionPlanForm.agenda.$error === true
            ? 'border border-red'
            : 'border border-gray-300'
        "
      />
      <p
        class="text-red"
        v-for="error in v$.actionPlanForm.agenda.$errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
      <label
        class="block text-gray-700 font-semibold text-xs"
        for="grid-first-name"
      >
        Objectives<span class="text-red">*</span>
      </label>
      <input
        class="block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
        id="grid-first-name"
        type="text"
        placeholder="Type in objectives"
        :maxlength="255"
        v-model="actionPlanForm.objectives"
        :disabled="!selected || viewMode"
        :class="
          v$.actionPlanForm.objectives.$error === true
            ? 'border border-red'
            : 'border border-gray-300'
        "
      />
      <p
        class="text-red"
        v-for="error in v$.actionPlanForm.objectives.$errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
      <label
        class="block text-gray-700 font-semibold text-xs"
        for="grid-last-name"
      >
        Specifics<span class="text-red">*</span>
      </label>
      <input
        class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        id="grid-last-name"
        type="text"
        :maxlength="255"
        placeholder="Add Specifics related to objectives"
        v-model="actionPlanForm.specific"
        :disabled="!selected || viewMode"
        :class="
          v$.actionPlanForm.specific.$error === true
            ? 'border border-red'
            : 'border border-gray-300'
        "
      />
      <p
        class="text-red"
        v-for="error in v$.actionPlanForm.specific.$errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
      <label
        class="block text-gray-700 font-semibold text-xs"
        for="grid-password"
      >
        Measure of Achievements<span class="text-red">*</span>
      </label>
      <input
        class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        id="grid-password"
        type="text"
        :maxlength="255"
        placeholder="Resources used to identify success"
        v-model="actionPlanForm.measure_of_achievement"
        :disabled="!selected || viewMode"
        :class="
          v$.actionPlanForm.measure_of_achievement.$error === true
            ? 'border border-red'
            : 'border border-gray-300'
        "
      />
      <p
        class="text-red"
        v-for="error in v$.actionPlanForm.measure_of_achievement.$errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
      <label class="block text-gray-700 font-semibold text-xs" for="grid-city">
        Achieveable<span class="text-red">*</span>
      </label>
      <input
        class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        id="grid-city"
        type="text"
        placeholder="Resource and support needed"
        :maxlength="255"
        v-model="actionPlanForm.achievable"
        :disabled="!selected || viewMode"
        :class="
          v$.actionPlanForm.achievable.$error === true
            ? 'border border-red'
            : 'border border-gray-300'
        "
      />
      <p
        class="text-red"
        v-for="error in v$.actionPlanForm.achievable.$errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
      <label class="block text-gray-700 font-semibold text-xs" for="grid-zip">
        Relevant<span class="text-red">*</span>
      </label>
      <input
        class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        id="grid-zip"
        type="text"
        placeholder="How will this impact the business"
        :maxlength="255"
        v-model="actionPlanForm.relevant"
        :disabled="!selected || viewMode"
        :class="
          v$.actionPlanForm.relevant.$error === true
            ? 'border border-red'
            : 'border border-gray-300'
        "
      />
      <p
        class="text-red"
        v-for="error in v$.actionPlanForm.relevant.$errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
      <label class="block text-gray-700 font-semibold text-xs" for="grid-zip">
        Time Frame<span class="text-red">*</span>
      </label>

      <div class="bg-white border border-gray-300 rounded">
        <DatePicker
          placeholder="Date to be achieved by"
          :selectedDate="actionPlanForm.time_frame"
          :isDisabled="!selected || viewMode"
          @selected-date="setTimeFrame"
          @cleared-date="setTimeFrame"
          :needMinDate="true"
          :class="
            v$.actionPlanForm.time_frame.$error === true
              ? 'border border-red'
              : ''
          "
          :key="date"
        />
      </div>
      <p
        class="text-red"
        v-for="error in v$.actionPlanForm.time_frame.$errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
    </form>
    <div v-if="props.mode === 'create'" class="flex flex-col gap-2">
      <h3 class="font-medium text-xl">Call For Meeting</h3>
      <label
        class="block text-gray-700 font-semibold text-xs py-2"
        for="grid-zip"
      >
        Add Participants<span class="text-red">*</span>
      </label>

      <div class="flex flex-wrap border border-gray-300 bg-white rounded">
        <div
          v-for="(participant, index) in actionPlanForm.meeting_participants"
          :key="index"
          class="bg-gray-100 justify-center items-center rounded-md m-2 px-3 py-2 text-xs"
        >
          <div class="flex justify-between items-center">
            <div class="text-gray-500 font-normal">
              {{ participant }}
            </div>
            <div class="w-4 ml-3 cursor-pointer" @click="removeChip(index)">
              <img src="../../../../../assets/images/cancel.png" />
            </div>
          </div>
        </div>
        <input
          :disabled="
            !selected || actionPlanForm.meeting_participants.length >= 4
          "
          type="email"
          placeholder="Please add meeting recipients"
          :id="'role'"
          class="appearance-none block w-full h-5 text-gray-700 py-3 px-4 my-3 leading-tight focus:outline-none focus:bg-white"
          v-model="participant"
          maxlength="100"
          minlength="8"
          @keyup.enter="addParticipant()"
        />
      </div>
      <small class="text-red" v-show="invalidEmail"
        >Please enter a valid email!</small
      >
      <small class="text-red" v-show="emailIsPresent"
        >Email is already present</small
      >
      <small
        class="text-orange"
        v-show="actionPlanForm.meeting_participants.length === 4"
      >
        Maximum Participant limit reached</small
      >
      <p
        class="text-red"
        v-show="!invalidEmail"
        v-for="error in v$.actionPlanForm.meeting_participants.$errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
      <label
        class="block text-gray-700 font-semibold text-xs py-2"
        for="grid-zip"
      >
        Date of Meeting<span class="text-red">*</span>
      </label>
      <div class="bg-white border border-gray-300 rounded">
        <DatePicker
          placeholder="Date of the meeting"
          :selectedDate="actionPlanForm.meeting_date"
          @selected-date="setMeetingDate"
          @cleared-date="setMeetingDate"
          :disabled="!selected || viewMode"
          :needMinDate="true"
          :class="
            v$.actionPlanForm.meeting_date.$error === true
              ? 'border border-red'
              : ''
          "
        />
      </div>
      <p
        class="text-red"
        v-for="error in v$.actionPlanForm.meeting_date.$errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
      <div class="flex justify-between mt-10">
        <button
          class="px-8 py-2 bg-transparent border border-teal text-teal rounded-md font-light"
          @click="$router.go(-1)"
        >
          Cancel
        </button>
        <button
          class="px-8 py-2 bg-teal border border-teal text-white rounded-md font-light"
          @click.prevent="submitForm"
          :disabled="disableCreateButton"
        >
          Create
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, defineProps } from "vue";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";
import icon from "@/assets/icons/success.svg";
import DatePicker from "../../../../../components/newUi/atoms/DatePicker.vue";
import moment from "moment";

const store = useStore();
const router = useRouter();
const route = useRoute();
const selectedQuestion = ref(null);
const selectedPractice = ref(null);
const invalidEmail = ref(null);
const emailIsPresent = ref(null);
const form = ref(null);
const participant = ref(null);
const selected = ref(false);
const qofTrue = route.params.qof;
const surveyId = route.params.surveyId;
const questions = ref([]);
const practices = ref([]);
const viewMode = ref(false);
const disablePracticeDropdown = ref(false);
const disableQuestionDropdown = ref(false);
const disableCreateButton = ref(false);

const hasEmail = (value) => {
  const emailRegex = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/;

  return emailRegex.test(value);
};

const addParticipant = () => {
  if (participant.value.length > 8 && hasEmail(participant.value)) {
    if (
      !actionPlanForm.value.meeting_participants.includes(participant.value)
    ) {
      actionPlanForm.value.meeting_participants.push(participant.value);
      participant.value = null;
      invalidEmail.value = false;

      emailIsPresent.value = false;
    } else {
      emailIsPresent.value = true;
    }
  } else {
    invalidEmail.value = true;
  }
};

const removeChip = (index) => {
  actionPlanForm.value.meeting_participants.splice(index, 1);
};

const props = defineProps({
  actionPlan: {
    type: Array,
    required: false,
  },
  mode: {
    type: String,
    required: false,
    default: "create",
  },
  qof: {
    type: String,
    default: "false",
  },
});

const actionPlanForm = ref({
  practice: null,
  indicator: "",
  sub_indicator: "",
  agenda: "",
  objectives: "",
  specific: "",
  measure_of_achievement: "",
  achievable: "",
  relevant: "",
  time_frame: "",
  meeting_date: "",
  meeting_participants: [],
});

const rules = computed(() => ({
  actionPlanForm: {
    agenda: { required },
    indicator: {
      required: requiredIf(function () {
        return qofTrue === "true";
      }),
    },
    sub_indicator: {
      required: requiredIf(function () {
        return qofTrue === "true";
      }),
    },
    objectives: { required },
    specific: { required },
    measure_of_achievement: { required },
    achievable: { required },
    relevant: { required },
    time_frame: { required },
    meeting_date: { required },
    meeting_participants: { required },
  },
}));

onMounted(async () => {
  practices.value = await store.dispatch("patientSurveys/loadPractices");
  if (props.actionPlan) {
    if (qofTrue === "true") {
      actionPlanForm.value.indicator = props.actionPlan.indicator;
      actionPlanForm.value.sub_indicator = props.actionPlan.sub_indicator;
    }
    actionPlanForm.value.agenda = props.actionPlan.agenda;
    actionPlanForm.value.objectives = props.actionPlan.objectives;
    actionPlanForm.value.specific = props.actionPlan.specific;
    actionPlanForm.value.measure_of_achievement =
      props.actionPlan.measure_of_achievement;
    actionPlanForm.value.achievable = props.actionPlan.achievable;
    actionPlanForm.value.relevant = props.actionPlan.relevant;
    actionPlanForm.value.time_frame = moment(
      props.actionPlan.time_frame
    ).format("MMM DD, YYYY");
    findQuestion(props.actionPlan.survey_question);
    findPractice(props.actionPlan.practice);
  }
  if (props.qof === "false") {
    if (props.mode === "view") {
      questions.value = await store.dispatch("actionPlans/fetchAllQuestions", {
        surveyId: surveyId,
      });
      findQuestion(props.actionPlan.survey_question);
      viewMode.value = true;
    } else {
      questions.value = await store.dispatch("actionPlans/fetchAllQuestions");
    }
    if (route.params.questionId) {
      findQuestion(route.params.questionId);
    }
    if (route.params.practiceId) {
      findPractice(route.params.practiceId);
    }
  }
});

const setTimeFrame = (dateSelected) => {
  if (dateSelected) {
    actionPlanForm.value.time_frame = dateSelected;
  } else {
    actionPlanForm.value.time_frame = null;
  }
};

const setMeetingDate = (dateSelected) => {
  if (dateSelected) {
    actionPlanForm.value.meeting_date = dateSelected;
  } else {
    actionPlanForm.value.meeting_date = null;
  }
};

const setQuestion = (question) => {
  selectedQuestion.value = question;
  actionPlanForm.value.survey_question = selectedQuestion.value.id;
  if (selectedQuestion.value && selectedPractice.value) {
    selected.value = true;
  }
  disableCreateButton.value = false;
};
const findQuestion = (question) => {
  for (let index = 0; index < questions.value.length; index++) {
    if (question == questions.value[index].id) {
      setQuestion(questions.value[index]);
      disableQuestionDropdown.value = true;
    }
  }
};

const setPractice = (practice) => {
  selectedPractice.value = practice;
  actionPlanForm.value.practice = selectedPractice.value.id;
  if (selectedPractice.value) {
    selected.value = true;
  }
  disableCreateButton.value = false;
};
const findPractice = (practice) => {
  for (let index = 0; index < practices.value.length; index++) {
    if (practice == practices.value[index].id) {
      setPractice(practices.value[index]);
      disablePracticeDropdown.value = true;
    }
  }
};

const v$ = useVuelidate(rules, { actionPlanForm });

const submitForm = async () => {
  await v$.value.$touch();
  if (v$.value.$error) {
    return;
  }
  disableCreateButton.value = true;
  if (qofTrue === "false") {
    actionPlanForm.value.survey_question = selectedQuestion.value.id;
    delete actionPlanForm.value["indicator"];
    delete actionPlanForm.value["sub_indicator"];
  }
  let response;
  if (qofTrue.includes("true")) {
    response = await store.dispatch(
      "actionPlans/createActionPlanQof",
      actionPlanForm.value
    );
  } else {
    response = await store.dispatch(
      "actionPlans/createActionPlan",
      actionPlanForm.value
    );
  }
  if (response.data.success) {
    Swal.fire({
      title: `Action Plan is created Successfully`,
      text: "Meeting is also created",
      iconHtml: `<img src="${icon}" />`,
      customClass: {
        title: "custom-title",
        popup: "border-radius",
        content: "custom-text",
        icon: "icon-border",
      },
      timer: 2000,
      showConfirmButton: false,
      background: `rgba(225, 249, 240, 1)`,
    });
    if (qofTrue === "true") {
      router.push({ name: "hq-monitoringOfPracticeFunctionsQofDashboard" });
    } else {
      router.push({ name: "hq-HqMonitoringOfPracticeFunctionsPatientSurvey" });
    }
  }
};
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 8px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}

::v-deep .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow: hidden;
}

::v-deep .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}
</style>
